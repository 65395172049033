/*
 * RequestsRpc.js 
 * 用于EOS-jsonRpc的请求
 * author:shuijunzi
 */
import { JsonRpc } from './eosjs';
import config from "./Config"

let baseUrl = config.httpEndpoint;
const rpc = new JsonRpc(baseUrl);

/**
 * 获取账户余额
 * @param {string} token 合约托管代码 anya.token
 * @param {string} accountName 要查询余额的账户
 * @param {string} symbol 符号 ANY
 */ 
export function getBalance(token, accountName, symbol){
    return rpc.get_currency_balance(token, accountName, symbol)
}

/**
 * 获取用户信息
 * @param {string} accName 合约账号
 */ 
export function getAccount (accName) {
    return rpc.get_account(accName)
}

/**
 * 指定数据表的查询结果 
 * @param {json} params 表名及条件
 */ 
export function getTableRows(params) {
    return rpc.get_table_rows({...params})
}

/**
 * 当前所接入EOS链的总体信息
 */ 
export function getInfo() {
    return rpc.get_info()
}

/**
 * 根据公钥获取账户
 * @param {string} publicKey 公钥 
 */ 
export function getKeyAccounts (publicKey) {
    return rpc.history_get_key_accounts(publicKey)
}