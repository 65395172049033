/*
 * Config.js 
 * 用于配置通用信息
 * author:shuijunzi
 */

const config = {
    httpEndpoint: 'https://www.stareast.io', //合约地址
    defaultPrivateKey: '5Jg9jiNyqR5Yc1hnvCgVYoXgGX9WATtZoJ3WXpCbLwLahYJobUy',
}

export default config;